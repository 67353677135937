import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  Arduino: "b7ee4f413894",
  Breadboard: "b7ee4f413894",
  "Erkek Jumper Kablolar": "b7ee4f413894",
  Resistors: "b7ee4f413894",
  "Push Button": "b7ee4f413894",
  Potansiyometre: "b7ee4f413894",
  "Light Dependent Resistor": "b7ee4f413894",
  LM35: "b7ee4f413894",
  "Ultrasonik Sensör": "b7ee4f413894",
  Led: "b7ee4f413894",

  "NodeJS & Express": "eddb2dd3bf40",
  "Johnny Five": "eddb2dd3bf40",

  "JS Arduino Blink": "221c8b3596e3",
  "NodeJS Arduino": "221c8b3596e3",

  "Led on/off/toggle": "14c30fce0c6",
  "Led Blink/Strobe ve Pulse": "14c30fce0c6",
  "Led Brightness API": "14c30fce0c6",
  "Led Fade API": "14c30fce0c6",

  "Button down/up/hold": "fe714a70f1c1",

  "Sensors & Inputs": "5a36cfcecccf",
  "Led & Outputs": "5a36cfcecccf",
  "Buzzers & Outputs": "5a36cfcecccf",
}

const stories = [
  {
    title: "Arduino Başlangıç Seti",
    postId: "b7ee4f413894",
  },
  {
    title: "JS ile Arduino Programlama (Blink)",
    postId: "221c8b3596e3",
  },
  {
    title: "NodeJS Express ve Arduino",
    postId: "eddb2dd3bf40",
  },
  {
    title: "JS ile Arduino ve 1 Led",
    postId: "14c30fce0c6",
  },
  {
    title: "JS ile Arduino düğmeyi algılama",
    postId: "fe714a70f1c1",
  },
  {
    title: "Arduino Başlangıç Seti Kurcalamaları",
    postId: "5a36cfcecccf",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "JS Arduino Programming",
  path: "arduino",
}

const ArduinoPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ArduinoPage
